import React from 'react'
// import {useTheme} from "../../contexts/useTheme";

const Content = ({flex, children, theme, transparent}) => {


    // const {theme} = useTheme()
    return(
        <div id='content' className={"content " + theme + (flex ? ' flex' : '') + (transparent ? ' transparent' : '')}>
            {children}
        </div>
    )
}

export default Content