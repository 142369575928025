import React, {useRef, useState} from 'react'
import appstore from '../../public/assets/appstore.png'
import playstore from '../../public/assets/googleplay.png'
import apkdownload from '../../public/assets/download-android-apk-logo.png'
import {ImageWrapper, iOSDownloadURL, androidDownloadURL, playstoreDownloadURL} from "../ImageWrapper/ImageWrapper";


export const getSlideClass = (index, activeSlide) => {
    switch (index) {
        case activeSlide:
            return 'active'
        case (activeSlide - 1):
            return 'back'
        case (activeSlide + 1):
            return 'front'
        case 4:
            if(activeSlide === 1) {
                return 'back'
            } else {
                return ''
            }
        case 1:
            if(activeSlide === 4) {
                return 'front'
            } else {
                return ''
            }
        default:
            return ''

    }
}

const AppBanner = () => {

    const [activeSlide, setActiveSlide] = useState(1)
    const button_timeout = useRef(false)

    const changeActiveSlide = (new_active_slide) => {
        button_timeout.current = true
        setActiveSlide(new_active_slide)
        setTimeout(function() {
            button_timeout.current = false
        }, 500);
    }

    return (
        <div id='appBanner' className='section light'>
            <div className="app-container">
                <div className="left">
                    <div className="zentech">Join the Beta of the brand new Nomo Chat</div>
                    <div className="header">Over 100,000 users
                        trust our Nomo
                    </div>
                    <div className="information bold">The Nomo App is your easy and secure access to the
                        Blockchain world.
                    </div>
                    <div className="information">The Nomo App will enable you to have secure and
                        carefree access to your digital assets at any time:
                    </div>
                    <div className="button-container">
                        {/*<Link href={'https://apps.apple.com/at/app/zeniq-app/id1586514584'}>*/}
                        <a href={iOSDownloadURL} target={'_blank'} rel={'noreferrer'}>
                            <ImageWrapper image={appstore} width={170} height={53}/></a>
                        {/*</Link>*/}
                        <a href={playstoreDownloadURL} target={"_blank"} rel={"noreferrer"}>
                        <div className="button">
                            <ImageWrapper image={playstore} width={170} height={50} />
                        </div>
                        </a>
                        {/* <a href={androidDownloadURL} target={'_blank'} rel={'noreferrer'}>
                            <div className="button">
                                <ImageWrapper image={apkdownload} width={170} height={50}/></div>
                        </a> */}
                    </div>
                </div>
                <div className="right">
                    <div className="slider">
                        <div className={"appscreen " + getSlideClass(1, activeSlide)}/>
                        <div className={"appscreen " + getSlideClass(2, activeSlide)}/>
                        <div className={"appscreen " + getSlideClass(3, activeSlide)}/>
                        <div className={"appscreen " + getSlideClass(4, activeSlide)}/>
                        <div className='button' onClick={() => {
                            !button_timeout.current && changeActiveSlide( activeSlide === 1 ? 4 : activeSlide - 1)
                        }}/>
                        <div className='button' onClick={() => {
                            !button_timeout.current && changeActiveSlide( activeSlide === 4 ? 1 : activeSlide + 1)
                        }}/>
                        <div className="shadow"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppBanner