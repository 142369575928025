import React from 'react'
import chat from '../../assets/chat.png'


const ChatBanner = () => {

    return (
        <div className="banner section chat light">
            <div className="banner-information-container">
                <div className="right">
                    <img src={chat.src} alt=''/>
                </div>
                <div className="left">
                    <div className="zentech">Join the Beta of the brand new Nomo Chat</div>
                    <div className="header">
                        Stay Connected with the Nomo's New Chat Feature
                    </div>
                    <div className="information bold">The chat feature is still in Beta and might experience some bugs, so please give us your feedback and help us make the perfect app for you!
                    </div>
                    <div className="information">Introducing the new chat feature on the Nomo App! Now you can easily connect and communicate with other users while managing your assets across multiple blockchains. Chat securely and conveniently all in one place
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ChatBanner