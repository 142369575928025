import {NextSeo} from "next-seo";
import Content from "../components/Content/Content";
import HeroBanner from "../components/HeroBanner/HeroBanner";
import AppBanner from "../components/AppBanner/AppBanner";
import ChatBanner from "../components/ChatBanner/ChatBanner";
import {useTheme} from "../contexts/useTheme";
import {useEffect} from "react";

export default function App() {
    const {changeTheme} = useTheme()
    useEffect(() => {
        changeTheme('dark')
    },[])

    return (
        <>
            <NextSeo
                title="Nomo"
                description="Download the newest version of Nomo"
            />
            <Content theme={'dark'}>
                <AppBanner/>
                <HeroBanner/>
                <ChatBanner/>
            </Content>
        </>
    );
}
